var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "v-app",
        { staticStyle: { overflow: "hidden" }, attrs: { id: "inspire" } },
        [
          _c(
            "v-snackbar",
            {
              attrs: { top: "", color: _vm.color },
              model: {
                value: _vm.snackbar,
                callback: function ($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar",
              },
            },
            [
              _vm.isHtml
                ? [_c("div", { domProps: { innerHTML: _vm._s(_vm.text) } })]
                : [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isException ? _vm.text : _vm.$t(_vm.text)) +
                        " "
                    ),
                  ],
            ],
            2
          ),
          _c(
            "v-main",
            {
              style:
                _vm.$route.path === "/profile" ? "padding: 43px 0px 0px" : "",
            },
            [
              _c(
                "v-container",
                {
                  staticClass: "container--app py-0",
                  class: _vm.$route.path === "/dashboard" ? "px-0" : "px-0",
                  attrs: { fluid: "" },
                },
                [
                  _vm.isAuthenticated ? _c("header-logo") : _vm._e(),
                  _c("router-view", {
                    class: _vm.timerData.isTimeRunning ? "mb-8" : "",
                    attrs: { test: _vm.test, shiftTime: _vm.shiftTime },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isAuthenticated ? _c("layout") : _vm._e(),
          _vm.showFormDialog
            ? _c("div", {
                staticClass: "notes-div",
                staticStyle: { top: "30%!important" },
              })
            : _vm._e(),
          _c(
            "v-fade-transition",
            [
              _vm.getNewContentAvailable
                ? _c(
                    "v-alert",
                    {
                      staticClass: "pa-2",
                      staticStyle: {
                        position: "fixed",
                        bottom: "-10px",
                        "z-index": "100",
                      },
                      attrs: {
                        prominent: "",
                        transition: "scale-transition",
                        type: "info",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-col", [
                            _vm._v(_vm._s(_vm.$t("message.common.newUpdate"))),
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "shrink" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { outlined: "" },
                                  on: { click: _vm.loadUpdateContent },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("message.common.refresh"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              staticClass: "mt-0",
              attrs: { "max-width": "400px", persistent: "" },
              model: {
                value: _vm.showInstalliOS,
                callback: function ($$v) {
                  _vm.showInstalliOS = $$v
                },
                expression: "showInstalliOS",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c("v-flex", { attrs: { xs11: "" } }, [
                        _c("h3", [_vm._v("Install")]),
                      ]),
                      _c(
                        "v-flex",
                        { staticClass: "text-xs-right", attrs: { xs1: "" } },
                        [
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.showInstalliOS = false
                                },
                              },
                            },
                            [_vm._v("mdi-close")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "pa-5" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("message.common.install")) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "mt-3" }),
                      _c(
                        "v-layout",
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "mt-2 text-xs-center",
                              attrs: { xs12: "" },
                            },
                            [
                              _c(
                                "b",
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("message.common.justTab")) +
                                      " "
                                  ),
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "#59a7ff" } },
                                    [_vm._v("mdi-open-in-app")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("message.common.addToHome"))
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }