var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          staticClass: "mt-0",
          attrs: { persistent: "", "max-width": "400px" },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  " " + _vm._s(_vm.$t("message.common.deleteConfirm")) + " "
                ),
              ]),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "pa-5" }, [
                _vm._v(" " + _vm._s(_vm.$t("message.common.areYouSure")) + " "),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        id: "deleteConfirm",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.confirmDelete },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.yes")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", id: "deleteClose" },
                      on: {
                        click: function ($event) {
                          _vm.showDialog = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.no")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-bottom-navigation",
        {
          staticClass: "grey darken-3 small_device_custom_spacing",
          style: "left: " + _vm.bottomNavigationLeftValue + "px !important;",
          attrs: { app: "", dark: "", "max-width": "1264px" },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _vm._l(_vm.getMenuLinks, function (button, index) {
                return [
                  (button.isShow !== undefined ? button.isShow : true)
                    ? _c(
                        "v-btn",
                        {
                          key: index + "_menu_navigation",
                          staticClass: "fixbuttondesign",
                          staticStyle: { color: "#fff !important" },
                          attrs: { icon: "", small: "", to: button.to },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.$t(button.text)))]),
                          _c("v-icon", [_vm._v(_vm._s(button.icon))]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "", top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "btnmsgbox",
                                  attrs: { icon: "", small: "" },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("span", { staticClass: "moretextmsg" }, [
                                _vm._v(_vm._s(_vm.$t("message.layout.more"))),
                              ]),
                              _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v("mdi-dots-horizontal"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "span",
                        {
                          staticClass: "overflow-text pl-3 font-weight-medium",
                        },
                        [_vm._v(_vm._s(_vm.userDetails.name))]
                      ),
                      _c("v-divider"),
                      _vm._l(_vm.menuItems, function (item, index) {
                        return _c(
                          "v-list-item",
                          {
                            key: index,
                            attrs: { dense: "" },
                            on: {
                              click: function ($event) {
                                return _vm.menuActions(item.click, item.value)
                              },
                            },
                          },
                          [
                            item.value !== "autoLogout"
                              ? _c(
                                  "v-list-item-title",
                                  { style: { "line-height": "3" } },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t(item.title)) + " "
                                    ),
                                  ]
                                )
                              : [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      style: { "line-height": "3" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t(item.title)))]
                                  ),
                                  _c("v-switch", {
                                    staticClass: "v-icon mb-n4 mt-0 ml-2",
                                    attrs: { color: "#000" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                      change: function ($event) {
                                        return _vm.handleSwitchChange($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.switchValue,
                                      callback: function ($$v) {
                                        _vm.switchValue = $$v
                                      },
                                      expression: "switchValue",
                                    },
                                  }),
                                ],
                          ],
                          2
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ]
      ),
      _c(
        "v-dialog",
        {
          staticClass: "mt-0",
          attrs: { persistent: "", "max-width": "400px" },
          model: {
            value: _vm.showConfirmationDialog,
            callback: function ($$v) {
              _vm.showConfirmationDialog = $$v
            },
            expression: "showConfirmationDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  " " + _vm._s(_vm.$t(_vm.confirmationPayload.title)) + " "
                ),
              ]),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "pa-5" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t(_vm.confirmationPayload.text)) + " "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        id: "confirmToProceed",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.confirmProceed },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.yes")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", id: "cancelProceed" },
                      on: {
                        click: function ($event) {
                          _vm.showConfirmationDialog = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.no")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }