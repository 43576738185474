var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "pa-0", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-1" },
            [
              _c(
                "v-row",
                { staticClass: "mx-0" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        xs: "12",
                        sm: "6",
                        md: "5",
                        lg: "4",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-1 text-center headline",
                          attrs: { outlined: "", tile: "" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.currentDay) +
                              " " +
                              _vm._s(_vm.currentDate) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mx-0 mb-5 mt-3 text-center" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0",
                      attrs: { cols: "12", align: "center" },
                    },
                    [
                      !_vm.timerData.isTimeRunning
                        ? [
                            _c(
                              "v-badge",
                              {
                                staticClass: "mr-5",
                                attrs: {
                                  bordered: "",
                                  color: "success",
                                  icon: "mdi-play",
                                  overlap: "",
                                },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-2",
                                    class:
                                      _vm.isDisabledTimer || _vm.isWeekLocked
                                        ? "include_stamp_attachment"
                                        : "",
                                    attrs: {
                                      disabled: !_vm.timerObj.shift_id
                                        ? true
                                        : _vm.disableButton
                                        ? true
                                        : false,
                                      color:
                                        _vm.isDisabledTimer || _vm.isWeekLocked
                                          ? ""
                                          : "success",
                                      tile: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.start()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("message.timer.start"))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "v-badge",
                              {
                                staticClass: "mx-2 mr-5",
                                attrs: {
                                  bordered: "",
                                  color: _vm.holdTime ? "orange" : "success",
                                  icon: "mdi-stop",
                                  overlap: "",
                                },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-2",
                                    attrs: {
                                      tile: "",
                                      color: _vm.holdTime
                                        ? "orange"
                                        : "success",
                                      dark: "",
                                      disabled: _vm.buttonDissable,
                                    },
                                    on: { click: _vm.stopTimer },
                                  },
                                  [_c("span", [_vm._v(_vm._s(_vm.time))])]
                                ),
                              ],
                              1
                            ),
                          ],
                      [
                        _c("input", {
                          ref: "uploader",
                          staticClass: "d-none",
                          attrs: { type: "file", accept: "image/*" },
                          on: { change: _vm.onFileChanged },
                        }),
                      ],
                      _vm.timerData.isTimeRunning
                        ? [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "mx-1",
                                                attrs: {
                                                  fab: "",
                                                  dark: "",
                                                  small: "",
                                                  color: "error",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showCancelDialog = true
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { dark: "" } },
                                                [_vm._v("mdi-cancel")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1381981544
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("message.timer.cancelTimer"))
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm._l(_vm.quickNavItems, function (item, index) {
                        return [
                          _c(
                            "v-tooltip",
                            {
                              key: index + "_navItem",
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "mx-1",
                                              attrs: {
                                                fab: "",
                                                dark: "",
                                                small: "",
                                                color: item.color,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$router.push(
                                                    item.to
                                                  )
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { dark: "" } },
                                              [_vm._v(_vm._s(item.icon))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t(item.tooltipText))),
                              ]),
                            ]
                          ),
                        ]
                      }),
                      [
                        _c(
                          "v-tooltip",
                          {
                            key: "shift_help_navItem",
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mx-1",
                                          attrs: {
                                            fab: "",
                                            dark: "",
                                            small: "",
                                            color: "primary",
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { dark: "" } }, [
                                          _vm._v("mdi-information"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            this.$i18n.locale == "en"
                              ? _c("span", [
                                  _vm._v("How Stemp works? "),
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v(
                                        "Both stemp and Manual hour entry will work based on the shift, where shift is mandatory."
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "Shift is userdefined in Manage hours"
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "If an shift is between 9:00 to 18:00, totally 9 hrs, if user starts at 9 it continue untill 18:00, at 18:00, the user will get an alert to stop or continue, if user wishes he can continue."
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "since shift is 9hrs, where he can start stemp at any time. For example, if user starts at 11:00 instead of 9:00, the stemp will alert user at 20:00(calculating 9 hrs)"
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "The stemp can be stopped in between too as per the user desired."
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "If user fails to stempout at shift, it automatically stops at 24hrs and there will be alert to the user on their next login."
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "The 24hrs will include (project hours + stemphours +Pausetime + Absence hours) per day."
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "If an stemp includes attachments, it will not logout automatically at 24hrs, instead an alert will arose to include attachment on their next login."
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _c("span", [
                                  _vm._v(" Hvordan virker Stemp? "),
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v(
                                        "Både stemp og Manuell timeregistrering vil fungere basert på skiftet, hvor skift er obligatorisk."
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "Skift er brukerdefinert i Administrer timer"
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "Hvis et skift er mellom 9:00 og 18:00, totalt 9 timer, hvis brukeren starter kl. 9, fortsetter det til kl. 18:00, kl. han kan fortsette."
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "siden skiftet er 9 timer, hvor han kan starte stemp når som helst. for eksempel, hvis brukeren starter kl. 11.00 i stedet for kl. 9.00, vil stemplet varsle brukeren kl. 20.00 (beregner 9 timer)"
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "Stemplet kan stoppes i mellom også etter brukerens bekvemmelighet."
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "Hvis brukeren ikke klarer å stoppe ved skiftet, stopper den automatisk ved 24 timer, og brukeren blir varslet ved neste pålogging."
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "De 24 timer vil inkludere (prosjekt timer + stemphours + pausetid + fravær timer) per dag."
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "Hvis en stemp inkluderer vedlegg, vil den ikke logge ut automatisk etter 24 timer, i stedet vil det oppstå et varsel om å inkludere vedlegg ved neste pålogging"
                                      ),
                                    ]),
                                  ]),
                                ]),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    {
                      staticClass: "px-0 py-1",
                      attrs: {
                        cols: "12",
                        xs: "12",
                        sm: "6",
                        md: "5",
                        lg: "4",
                      },
                    },
                    [
                      _c(
                        "v-form",
                        { ref: "shiftForm" },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              label: _vm.$t("message.timer.shift"),
                              clearable: "",
                              items: _vm.shifts,
                              rules: _vm.shiftRules,
                              "item-text": function (a) {
                                return _vm.$i18n.locale === "en"
                                  ? a.en_name
                                  : a.no_name
                              },
                              "item-value": "id",
                              dense: "",
                              "hide-details": "",
                              outlined: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.shiftChange()
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function (data) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$i18n.locale === "en"
                                            ? data.item.en_name
                                            : data.item.no_name
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.timerObj.shift_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.timerObj, "shift_id", $$v)
                              },
                              expression: "timerObj.shift_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { right: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: { color: "primary", dark: "" },
                                        },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v(" mdi-information ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", [
                            _c("p", { staticClass: "caption mx-2 my-0" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("message.common.stempHint"))
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mx-0" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0",
                      attrs: {
                        cols: "12",
                        xs: "12",
                        sm: "6",
                        md: "5",
                        lg: "4",
                      },
                    },
                    [
                      _c("form-data", {
                        ref: "hourReference",
                        attrs: {
                          references: _vm.formReferences,
                          model: _vm.timerObj,
                        },
                        on: {
                          "update:references": function ($event) {
                            _vm.formReferences = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mx-0 mt-2" },
                _vm._l(_vm.allAttachments, function (image) {
                  return _c(
                    "v-flex",
                    { key: image.id, staticClass: "xs4 xm3 lg2 pa-1" },
                    [
                      _c("v-img", {
                        attrs: {
                          width: "100",
                          height: "50",
                          src: _vm.getBaseUrl + "/Resources/" + image.fileguid,
                        },
                        on: {
                          click: function ($event) {
                            _vm.selectedImage =
                              _vm.getBaseUrl + "/Resources/" + image.fileguid
                            _vm.showImageInFullScreen = true
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          model: {
            value: _vm.showImageInFullScreen,
            callback: function ($$v) {
              _vm.showImageInFullScreen = $$v
            },
            expression: "showImageInFullScreen",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white",
                      attrs: { fab: "", elevation: "0" },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { large: "" },
                          on: {
                            click: function ($event) {
                              _vm.showImageInFullScreen = false
                            },
                          },
                        },
                        [_vm._v("mdi-close")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [_c("v-img", { attrs: { src: _vm.selectedImage } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "mt-0",
          attrs: { persistent: "", "max-width": "400px" },
          model: {
            value: _vm.showCancelDialog,
            callback: function ($$v) {
              _vm.showCancelDialog = $$v
            },
            expression: "showCancelDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-0", attrs: { flat: "" } },
            [
              _c("v-card-title", [
                _vm._v(
                  " " + _vm._s(_vm.$t("message.common.confirmCancel")) + " "
                ),
              ]),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "pa-5" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("message.common.areYouSureCancel")) + " "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        id: "confirmCancel",
                        loading: _vm.cancelLoading,
                      },
                      on: { click: _vm.confirmCancel },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.yes")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", id: "closeCancel" },
                      on: {
                        click: function ($event) {
                          _vm.showCancelDialog = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.no")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.shiftEndDialog,
            callback: function ($$v) {
              _vm.shiftEndDialog = $$v
            },
            expression: "shiftEndDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(_vm._s(_vm.$t("message.common.confirmAddHour"))),
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "pt-4 font-weight-bold text-h6" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("message.common.doYouWantContinue")) +
                      " "
                  ),
                ]
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "success",
                      on: {
                        click: function ($event) {
                          return _vm.continueExceeding()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.shifts.continue")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "error",
                      on: {
                        click: function ($event) {
                          return _vm.popupStopTimer()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.timer.stop")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500", persistent: "" },
          model: {
            value: _vm.exeedValidaion,
            callback: function ($$v) {
              _vm.exeedValidaion = $$v
            },
            expression: "exeedValidaion",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  " " + _vm._s(_vm.$t("message.common.reachedHours")) + " "
                ),
              ]),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "pt-4 text-h6" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("message.common.on")) +
                    " " +
                    _vm._s(_vm.exeedDate) +
                    " " +
                    _vm._s(_vm.$t("message.common.hoursExeed")) +
                    " "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary",
                      on: {
                        click: function ($event) {
                          _vm.exeedValidaion = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.common.ok")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }